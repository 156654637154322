<template>
  <v-autocomplete
    ref="autocompleteRef"
    class="rcc-autocomplete"
    v-model="selectedValues"
    :items="items"
    :label="label"
    :disabled="isDisabled"
    :rules="rules"
    :item-text="showBy"
    :loading="isLoading"
    :one-time-load="oneTimeLoad"
    hide-no-data
    hide-details
    hide-selected
    return-object
    outlined
    dense
    chips
    small-chips
    multiple
  >
    <template v-slot:selection="data">
      <v-chip
        class="rcc-autocomplete__chip"
        outlined
        close
        @click:close="remove(data.item)"
      >
        <span>{{ data.item[showBy] }}</span>
      </v-chip>
    </template>

    <template v-slot:item="data">
      <v-list-item-content v-text="data.item[showBy]"></v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>

export default {
  name: 'RccAutocomplete',

  data() {
    return {
      values: [],
      search: null,
      isLoading: false,
      items: []
    }
  },

  props: {
    label: {
      type: String,
      default: ''
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    width: {
      type: String,
      default: null
    },

    searchMethod: {
      type: Function,
      default: () => {}
    },

    value: {
      type: Array,
      default: () => []
    },

    chooseBy: {
      type: String,
      default: 'id'
    },

    showBy: {
      type: String,
      default: 'value'
    },

    rules: {
      type: Array,
      default: () => []
    },

    oneTimeLoad: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    selectedValues: {
      get() {
        return this.value || []
      },

      set(value) {
        this.$emit('input', value)
      }
    }
  },

  watch: {
    search (value) {
      if (this.isLoading || !this.searchMethod) return

      this.load()
    }
  },

  mounted() {
    if (this.width) {
      this.$refs.autocompleteRef.$el.style.width = this.width
    }

    if (this.oneTimeLoad && this.searchMethod) {
      this.load()
    }
  },

  methods: {
    remove (item) {
      const index = this.selectedValues.indexOf(item)
      if (index >= 0) this.selectedValues = this.selectedValues.filter(value => value !== item)
    },

    load() {
      this.isLoading = true

      this.searchMethod().then(({ items }) => {
        this.items = items
      }).finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style lang="scss">
.rcc-autocomplete {
  .v-input__slot {
    background: $text-input-bg !important;
  }

  fieldset {
    border-color: $text-input-border-color;
  }

  &:hover {
    fieldset {
      border-color: $text-input-hovered-border-color;
    }
  }

  .v-select__selections {
    padding: 6px 0 !important;
  }

  .v-label, .v-select__selection, .v-list-item__title {
    font-size: $base-font-size;
  }

  &.v-input--is-disabled {
    fieldset {
      border-color: $text-input-disabled-border-color;
    }

    &:hover {
      fieldset {
        border-color: $text-input-disabled-border-color;
      }
    }
  }

  .v-chip {
    max-width: 300px;
    padding-right: 10px;
    margin: 1px 4px !important;

    .v-chip__content {
      padding-right: 17px;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .v-icon {
      position: absolute;
      right: 0;
    }
  }
}
</style>
