<template>
  <tr
    class="rcc-row"
    :class="{'rcc-row_attention': row.isAttention}"
  >
    <rcc-cell
      v-for="(headerItem, index) in headers"
      :key="index"
      :value="row[headerItem.value]"
      :modifiers="headerItem.colModifiers"
      @edit-action="$router.push({ path: headerItem.editPath + row.id })"
    />
  </tr>
</template>

<script>
import RccCell from './rcc-cell'

export default {
  name: 'RccRow',

  components: { RccCell },

  props: {
    row: {
      type: Object,
      default: () => ({})
    },

    headers: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-row {
  line-height: 1.3;
  background: #ffffff;
  height: 20px;
  min-height: 20px;

  &_attention {
    background: $data-table-row-attention-bg;
  }
}
</style>
