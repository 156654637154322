<template>
  <div ref="rccTableRef" class="rcc-table">
    <div v-if="isShowFilters && filters.length > 0" ref="filtersRef" class="rcc-table__filters">
      <rcc-filters :filters="filters" @remove="handleFiltersRemove" />
    </div>

    <v-data-table
      ref="dataTableRef"
      :headers="localHeaders"
      :items="localRows"
      :page.sync="currentPage"
      :items-per-page="itemsPerPage"
      :disable-sort="true"
      hide-default-footer
      hide-default-header
      :fixed-header="isFixedHeader"
      :loading="isLoading"
      class="rcc-table__table-wrapper"
    >
      <template v-slot:header>
        <thead class="thead">
          <slot name="head">
            <tr>
              <th v-for="(headerItem, index) in localHeaders" :key="index" :width="headerItem.width">
                <rcc-header-filter
                  :ref="`${headerItem.customFilter.name || headerItem.value}HeaderFilter`"
                  :key="index"
                  v-if="headerItem.customFilter"
                  :text="headerItem.text"
                  :filter="headerItem.customFilter"
                  @select="handleHeaderFilterSelect(
                    (headerItem.customFilter.name || headerItem.value), headerItem.text, $event
                  )"
                />

                <rcc-header-sorter
                  v-else-if="headerItem.sortable"
                  :text="headerItem.text"
                  :direction="headerItem.sortDirection"
                  @sort="handleSort(headerItem.value, $event)"
                />

                <template v-else>
                  {{ headerItem.text }}
                </template>
              </th>
            </tr>
          </slot>
        </thead>
      </template>

        <template v-slot:body="">
          <tbody v-if="localRows.length > 0 && ! isLoading">
            <slot name="body" v-bind:rows="localRows">
              <rcc-row
                v-for="(row, index) in localRows"
                :key="index"
                :headers="localHeaders"
                :row="row"
              />
            </slot>

            <slot name="body-append" />
          </tbody>

          <tbody v-else>
            <tr>
              <td :colspan="localHeaders.length" class="no-data">
                {{ isLoading ? 'Подождите, идет загрузка данных...' : 'Нет данных для отображения' }}
              </td>
            </tr>
          </tbody>
      </template>
    </v-data-table>

    <rcc-pagination :key="pageCountKey" ref="paginationRef" :page.sync="currentPage" :pageCount="pageCount" />
  </div>
</template>

<script>
import RccHeaderFilter from './rcc-header-filter'
import RccPagination from './rcc-pagination'
import RccFilters from 'Components/filters'
import RccRow from './rcc-row'
import RccHeaderSorter from './rcc-header-sorter'

export default {
  components: {
    RccFilters,
    RccHeaderFilter,
    RccRow,
    RccPagination,
    RccHeaderSorter
  },

  props: {
    page: {
      type: Number,
      default: 1
    },

    pageCount: {
      type: Number,
      default: 1
    },

    itemsPerPage: {
      type: Number,
      default: 10
    },

    headers: {
      type: Array,
      default: () => []
    },

    rows: {
      type: Array,
      default: () => []
    },

    isFixedHeader: {
      type: Boolean,
      default: false
    },

    isShowFilters: {
      type: Boolean,
      default: true
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      filters: [],
      pageCountKey: 1,
      freeHeight: null,
      localRows: [],
      localHeaders: []
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.page
      },

      set(value) {
        this.$emit('update:page', value )
      }
    },

    tableWrapper() {
      return this.$refs.dataTableRef.$el.childNodes[0]
    }
  },

  created() {
    window.addEventListener('resize', this.resizeTable)
  },

  mounted() {
    this.setLocalRows(this.rows, true)
    this.localHeaders = [...this.headers]
    this.resizeTable()
  },

  destroyed() {
    window.removeEventListener('resize', this.resizeTable)
  },

  watch: {
    pageCount() {
      this.pageCountKey++
    },

    rows() {
      this.resizeTable()
      this.setLocalRows(this.rows, true)
    },

    filters() {
      this.resizeTable()
    },

    headers() {
      this.localHeaders = [...this.headers]
    }
  },

  methods: {
    handleHeaderFilterSelect(name, text, options) {
      const filterOptions = { name, text: `${text}: ${options.text}`, value: options.value }
      const filterIndex = this.filters.findIndex(filter => filter.name === name)

      if (options.text === '') {
        this.handleFiltersRemove(name)
        return
      }

      if (filterIndex >= 0) {
        this.$set(this.filters, filterIndex, filterOptions)
      } else {
        this.filters.push(filterOptions)
      }

      this.filtersEmitter()
    },

    handleFiltersRemove(name) {
      this.filters = this.filters.filter(item => item.name !== name)
      this.resetFilter(name)
      this.filtersEmitter()
    },

    filtersEmitter() {
      this.$emit('filters-changed', this.filters)
    },

    clearFilters() {
      this.filters.forEach(filter => this.resetFilter(filter.name))
      this.filters = []
      this.$emit('filters-changed', this.filters)
    },

    resetFilter(name) {
      this.$refs[name + 'HeaderFilter'] && this.$refs[name + 'HeaderFilter'][0].resetFilter()
    },

    resizeTable() {
      this.$nextTick(() => {
        this.$refs.dataTableRef.$el.classList.add('rcc-table__table-wrapper_is-hide')
        this.freeHeight = null

        this.calculateTableHeight()
      })
    },

    calculateTableHeight() {
      if (this.freeHeight === null) {
        const commonHeight = this.$refs.rccTableRef.offsetParent.offsetHeight

        this.freeHeight =
          commonHeight -
          ((this.$refs.filtersRef && this.$refs.filtersRef.offsetHeight) || 0) -
          (this.$refs.paginationRef && this.$refs.paginationRef.$el.offsetHeight) || 0
      }

      this.$refs.dataTableRef.$el.classList.remove('rcc-table__table-wrapper_is-hide')
      this.tableWrapper.style.maxHeight = this.freeHeight + 'px'
    },

    setFilters(filters) {
      this.filters = filters
    },

    setLocalRows(rows, checkSort) {
      this.localRows = [...rows]

      if (checkSort) {
        const { value, sortDirection } = this.localHeaders.find(item => item.sortable && item.sortDirection) || {}

        if (!value) {
          return
        }

        this.handleSort(value, sortDirection)
      }
    },

    handleSort(cellValue, direction) {
      this.localHeaders = this.localHeaders.map(item => {
        if (item.value === cellValue) {
          return { ...item, sortDirection: direction }
        } else if (item.sortable) {
          return { ...item, sortDirection: null }
        }

        return item
      })

      switch(direction) {
        case 'asc':
          this.localRows.sort((a, b) => a[cellValue] - b[cellValue])
          break
        case 'desc':
          this.localRows.sort((a, b) => b[cellValue] - a[cellValue])
          break
        default:
          this.setLocalRows(this.rows)
          break
      }
    }
  }
}
</script>

<style lang="scss">
.rcc-table {
  width: 100%;

  &__table-wrapper {
    border-radius: 20px 20px 0 0;
    overflow: hidden;

    &_is-hide {
      display: none;
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .v-data-table {
    background: transparent;
    background: white;

    &__wrapper {
      pointer-events: all;
    }
  }

  .thead {
    background: $data-table-secondary-bg;
    min-height: $data-table-header-and-footer-min-height;

    tr {
      background: transparent;
      line-height: 1.3;
    }

    th {
      background: $data-table-secondary-bg !important;
      padding: $data-table-header-and-footer-paddings !important;
      height: 30px !important;
      font-size: 13px !important;
    }
  }

  tbody {
    background: white;
    pointer-events: all;
  }

  .no-data {
    height: 100%;
    text-align: center;
    pointer-events: none;
  }
}
</style>

<style lang="scss">
.rcc-table {
  .v-data-table__progress {
    th {
      background: white !important;
      padding: 0 !important;
    }

    .v-progress-linear__indeterminate .short {
      height: 10px;
    }
  }
}
</style>
