<template>
  <div :class="classes">
    {{ text }}
    <v-menu
      v-model="isShowMenu"
      transition="scroll-y-transition"
      :close-on-content-click="false"
      max-height="500px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon v-if="isActive" small>mdi-filter-menu</v-icon>
          <v-icon v-else>mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <template v-if="filter.type === 'list'">
        <v-list v-if="filter.options && filter.options.length > 0">
          <v-list-item-group
            v-model="value"
            :mandatory="!!value"
            color="primary"
          >
            <v-list-item
              v-for="(option, index) in filter.options"
              :key="index"
              link
              @click="activateFilter({ text: option.text, value: option.value })"
            >
              <v-list-item-title>{{ option.text }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <div v-else class="rcc-header-filter__list-no-options">Нет доступных опций</div>
      </template>

      <rcc-datepicker
        v-else-if="filter.type === 'datepicker'"
        ref="datepicker"
        v-model="value"
        @input="activateFilter({ text: datePickerText, value })"
        isRange
      />

      <v-container
        class="rcc-header-filter__container"
        v-else-if="filter.type === 'search'"
      >
        <rcc-search
          :key="key"
          :label="filter.label"
          v-model="value"
          width="250px"
          :type="filter.inputType"
          @input="activateFilter({ text: $event, value: $event })"
        />
      </v-container>

      <v-container
        v-if="filter.type === 'autocomplete'"
        class="rcc-header-filter__container"
      >
        <rcc-autocomplete
          v-model="value"
          show-by="name"
          :items="filter.options"
          :label="filter.label"
          width="350px"
          hide-details
          @input="activateFilter({ text: ($event || ''), value: $event })"
        />
      </v-container>

      <v-container
        class="rcc-header-filter__container"
        v-else-if="filter.type === 'multiple-autocomplete'"
      >
        <rcc-multiple-autocomplete
          v-model="value"
          :label="filter.label"
          :search-method="filter.callback"
          show-by="name"
          width="350px"
          one-time-load
          @input="activateFilter({ text: autocompleteValues($event), value: $event.map(item => item.id) })"
        />
      </v-container>
    </v-menu>
  </div>
</template>

<script>
import RccSearch from 'Components/controls/rcc-search'
import RccDatepicker from 'Components/controls/rcc-datepicker'
import RccMultipleAutocomplete from 'Components/controls/rcc-autocomplete'
import RccAutocomplete from 'Components/controls/selects/autocomplete'

export default {
  components: { RccSearch, RccDatepicker, RccMultipleAutocomplete, RccAutocomplete },

  name: 'RccHeaderFilter',

  props: {
    text: {
      type: String,
      default: ''
    },

    filter: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      key: 1,
      isActive: false,
      value: null,
      isShowMenu: false
    }
  },

  computed: {
    datePickerText() {
      if (this.value.length === 1 || (this.value.length === 2 && this.value[0] === this.value[1])) {
        return this.value[0]
      }

      return this.value.join(' - ')
    },

    classes() {
      return [
        'rcc-header-filter',
        this.$store.isTableEditingEnabled && 'rcc-header-filter_is-no-pointers-events'
      ]
    }
  },

  watch: {
    isShowMenu(isShow) {
      if (!isShow) {
        this.key += 1
      }
    },

    value(value) {
      if (!value) {
        this.resetFilter()
      }
    }
  },

  methods: {
    activateFilter(value) {
      const filterData = { ...value }

      if (!value.value) {
        filterData.value = value.text
      }

      if (this.filter.type !== 'multiple-autocomplete' && (this.filter.type !== 'datepicker' || this.value.length === 2)) {
        this.isShowMenu = false
      }

      this.$emit('select', filterData)
      this.isActive = true
    },

    autocompleteValues(value) {
      return value.reduce((prevValue, { name }, index) => {
        return index === 0 ? name : prevValue += ', ' + name
      }, '')
    },

    resetFilter() {
      this.isActive = false
      this.value = null

      if (this.$refs.datepicker) {
        this.$refs.datepicker.resetData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-header-filter {
  display: flex;
  align-items: center;

  &__container {
    background: white;
  }

  &__list-no-options {
    background: white;
    padding: 10px;
  }

  &_is-no-pointers-events {
    pointer-events: none;
  }
}
</style>
