export default {
  data() {
    return {
      filters: [],
      rows: [],
      page: 1,
      isDataLoading: false,
      pagesCount: 1
    }
  },

  mounted() {
    if (this.noMixinMount) {
      return
    }

    this.loadData({})
  },

  watch: {
    page() {
      this.loadData(this.filtersQuery)
    }
  },

  computed: {
    rowsOnTable() {
      return this.$constants.rowsOnTable
    },

    filtersQuery() {
      const filtersObj = {}

      this.filters.forEach(filter => {
        filtersObj[filter.name] = filter.value
      })

      return filtersObj
    },

    headers() {
      return this.editAccess ? this.headerData : this.headerData.filter(item => item.value !== 'edit')
    },

    editAccess() {
      return this.$route.meta.editAccess.includes(this.$store.user.role)
    }
  },

  methods: {
    handleFiltersChanged(filters) {
      this.page = 1
      this.filters = filters
      this.loadData(this.filtersQuery)
    },

    resetTableData() {
      this.pagesCount = 1
      this.rows = []
    },

    disableLoadingIndicator() {
      this.isDataLoading = false
    },

    addFilter(name, text, options) {
      const filterOptions = { name, text: `${text}: ${options.text}`, value: options.value }
      const filterIndex = this.filters.findIndex(filter => filter.name === name)

      if (filterIndex >= 0) {
        this.$set(this.filters, filterIndex, filterOptions)
      } else {
        this.filters.push(filterOptions)
      }

      this.handleFiltersChanged(this.filters)
    },

    removeFilter(name) {
      this.resetFilter(name)
      this.handleFiltersChanged(this.filters.filter(item => item.name !== name))
    }
  }
}
